import React from 'react'
import "../css/body.css"

export default function News() {
  return (
    <div id='news' className='white-box'>
        <div className='sect-box'>
               <h2>Latest News</h2>
                <div className='line-box'>
                    <div className='line'></div>
                    <h1>O</h1>
                    <div className='line'></div>
                </div>
                <p style={{color: "#000"}}>Know the values that serves as the pillars to our cause</p>
        </div>
        <div className='news-flex'>
            <article>
                <div className='new-media'>
                    <img src={require("../assests/img/talk.jpg")} />
                <section><p>Add engage with the media</p></section>
                </div>
                <a>Read More</a>
            </article>
            <article>
                <div className='new-media'>
                    <img src={require("../assests/img/engage.jpg")} />
                <section><p>Getting Involved</p></section>
                </div>
                <a>Read More</a>
            </article>
            <article>
                <div className='new-media'>
                    <img src={require("../assests/img/press.jpg")} />
                <section><p>Morning Rush</p></section>
                </div>
                <a>Read More</a>
            </article>
        </div>
    </div>
  )
}
