import React from 'react'
import Summary from './summary'
import dshGif from "../../../svg/explore.gif"
import file from "../../../svg/create.svg";
import task from "../../../svg/tasklist.svg";
import leave from "../../../svg/leave.svg";

export default function Content({menu}) {
  return (
    <div>
        <section class='dsh-bd'>
            <div class='texts'>
                <p>Welcome back</p>
                <h1>Magrette Y. Davis</h1>
                <p class='hint'>What do you want to do today?</p>
            </div>
            <div class='dsh-act'>
                <div class='fileIn'><img src={file} /><p>File In</p></div>
                <div class='app'><img src={task} /><p>New Appointment</p></div>
                <div class='leave'><img src={leave} /><p>Request a Leave</p></div>
            </div>   
        </section>
        <article>
            <Summary title={"Gross Income"} result={"$2500"} mini={"Allowance $2000"} />
            <Summary title={"Debts & Cuts"} result={"$500"} mini={"Unpaid: $0"} />
            <Summary title={"Net Income"} result={"$2000"} mini={"Paid"} />
        </article>  
        <div class='details'>
            <div id='container'>
                <img src={dshGif} />
                <div className='txt-cnt'>
                    <h1>Welcome to your Dashboard</h1>
                    <p>Keep track of your records while properly managing your staffs</p>
                </div>
            </div>
        </div>
    </div>
  )
}
