import React, { Component } from 'react'
import "../css/banner.css";
import "../css/min/iphon-android.css";

export default class Banner extends Component {
  render() {
    return (
      <div id='Banner' className='container'>
        <div className='overlay'>
            <h1>Together We Will Move Liberia Forward!</h1>
            <p>Liberia is all we'll ever have. Join our quest for transparency, upholding the rule of law and good governance</p>
            <div className='act-box'>
                <a className='cnt'>Contact Us</a>
                <a className='lmr'>Learn More</a>
            </div>
        </div>
      </div>
    )
  }
}
