import React, { useCallback, useEffect, useState } from 'react'
import '../../../styles/side.css'
import help from "../../../svg/help.svg"
import logout from "../../../svg/logout.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxArchive, faCalendar, faChartColumn, faChartPie, faFile, faGlobe, faNewspaper, faPaintRoller, faSackDollar, faTools, faUserGear, faUserPlus, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'

export default function SideBar() {
    const [click, setClicked] = useState(false)
    
    const menuList = [
        {name: "Account Settings", href: "/gen", svg: faUserGear, id:"Acc"},
        {name: "Attendance", href: "", svg: faCalendar, id:"tsk"},
        {name: "Analytics", href: "", svg: faChartColumn, id:"tsk"},
        {name: "Budget Analysis", href: "", svg: faChartPie, id:"tsk"},
        {name: "Salary Structure", href: "", svg: faSackDollar, id:"tsk"},
        {name: "Applications", href: "", svg: faFile, id:"rep"},
        {name: "Add New Staff", href: "", svg: faUserPlus, id:"Acc"},
        {name: "Archives", href: "", svg: faBoxArchive, id:"Acc"},
        {name: "Press Briefings", href: "", svg: faNewspaper, id:"Acc"},
        {name: "Users", href: "", svg: faUsers, id:"Acc"},
        {name: "Theme", href: "", svg: faPaintRoller, id:"Acc"},
        {name: "Site Settings", href: "", svg: faGlobe, id:"prg"},
    ] 
  return (
    <div id='inner-side' style={{width: "200px"}}>
        <section>
            <div id='side-menu'>
                {menuList.map(item => {
                    return(
                        <div id={item.id} className='menu-item'><FontAwesomeIcon icon={item.svg} /><Link to={item.href}>{item.name}</Link></div>
                    )
                })}
            </div>
                <div id='advance'>
                <div className='av-item'>
                <img src={help} /><p>Report a problem</p></div>
                <div className='av-item'>
                <img src={logout} /><p>Log Out</p></div></div>
        </section>
    </div>
  )
}
