import React from 'react';
import "../css/about.css"
import MyImage from '../components/img';
import Header from '../components/header';
import Footer from '../components/footer';

export default function About() {
  return (
    <div>
        <Header />
        <div className="banner-container">
        <div className="hd-text">
            <h2>About</h2>
        </div>
    </div>

    <main>
        <div className="mn-hd">
            <h3>Our Company</h3>
            <p>Bea Mountain Mining Company is a mineral exploration company with high-quality gold and base metal projects in Western Liberia. The Company’s key focus is exploration and development of the high-grade Side Well Gold Project near the historic gold camp of Kinjor, Grand Capemount County.</p>
        </div>

        <div className="md-item">
            <MyImage src={require("../assests/img/visionary.png")}></MyImage>
            
            <div className="texts">
                <h3>OUR VISSION</h3>
                <p>To be the best example for ethical exploration and mineral development.</p>
            </div>
        </div>
        <div className="this md-item">
            <div className="texts">
                <h3>OUR MISSION</h3>
                <p>We create sustainable value through the discovery and development of mineral deposits using technical excellence and respectful collaboration with all stakeholders.</p>
            </div>
            <img src={require("../assests/img/target.png")} />
        </div>
        <div className="md-item">
            <img src={require("../assests/img/quality.png")} />
            <div className="texts">
                <h3>OUR VALUES</h3>
                <ul>
                    <li>Respect</li>
                    <li>Honesty</li>
                    <li>Passion & Committment</li>
                    <li>Transparent Communication</li>
                    <li>Pride</li>
                </ul>
            </div>
        </div>
    </main>

    <section id="sub">
        <div className="overlay">
            <div className="sub-form">
                <h3>A LOT IS HAPPENING AT BMMC THIS YEAR. SIGN UP TO OUR NEWS LETTER TO RECIEVE THE LATEST AS THEY UNFOLD</h3>
                <form action="">
                    <input placeholder="Full Name" type="text" />
                    <input placeholder="Company" type="text" />
                    <input type="email" placeholder="Email" />
                </form>
                <button>Subscribe For Updates</button>
            </div>
        </div>
    </section>   
    <Footer />      
    </div>
  )
}
