import React, { useEffect, useState } from 'react'
import "./admin.css"
import happy from "../website/assests/svg/undraw_happy_news_re_tsbd.svg"
import globe from "../website/assests/svg/globe.svg";
import bird from "../website/assests/img/welcome.png";
import login from "../website/assests/img/log-in.png";
import { Link, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';

export default function Admin() {

if(( window.innerWidth <= 700 )){
    // true for mobile device
    Navigate({to: "/"})
}else{
    // false for not mobile device
    console.log("welcome");
    
}

function go() {Navigate({to: "/dashboard"})};
 
  return (
    <div id='body'>
    <div className="admin-container">
            <div className="svg-box">
                <img src={login} alt="" />
            </div>
            <div className="form-box">
                <div className="heading-box">
                    <h2>Office of Hon. Dillon</h2>
                </div>
                <h3>Enter Info to login</h3>
                <div className="username">
                    <label>Username</label>
                    <div className='input'>
                      <FontAwesomeIcon icon={faUser} />
                      <input placeholder="Enter your username" type="text" />
                    </div>
                </div>
                <div className="password">
                    <label>Password</label>
                    <div className='input'>
                       <FontAwesomeIcon icon={faLock} />
                       <input placeholder="******" type="text" />
                    </div>
                </div>

                <p className="fgp">Forgot Password?</p>
                <a id='login' href='/dashboard'>Log in</a>

                <div className="etc">
                    <div className="line"></div>
                    <p className="inner-text">Or continue to</p>
                    <div className="line"></div>
                </div>
                <img id="globe" src={globe} />
                <Link to={"/"} id="web">Website</Link>
            </div>
        </div>
     </div>   
     
  )
}
