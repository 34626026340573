import { faAdd, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function Archive() {
  return (
    <div>
        <div class='sch-cont'>
            <h2>Documents & Files</h2>
            <div class='sch'><FontAwesomeIcon icon={faSearch} /><input type='text' placeholder='Find a document' /></div>
        </div>
        <div id='rst' class='rst-cont'>
            <div class='ach-tab-box'>
                <div class='tabs'>
                    <div class='tab-item tab-active'><p>Scanned</p></div>
                    <div class='tab-item'><p>Logistics</p></div>
                    <div class='tab-item'><p>Services</p></div>
                    <div class='tab-item'><p>Donations</p></div>
                </div>
            </div>
            <div id='tbs-cl' class='tabs-content-box'>
                <div id='tb-cnt'>
                    <div class='tb'>
                        <div class='first-box'>
                            <FontAwesomeIcon icon={faAdd} /><h4>May Financial Report</h4>
                        </div>
                        <p>$24.66</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>
  )
}
