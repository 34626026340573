import React from 'react'
import Dillon from "../../../../website/assests/img/dillon2.jpg"
import abe from "../../../../website/assests/img/add-standing.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default function Staffs() {
  return (
    <div>
        <div class='sch-cont'>
            <h2>Staffs</h2>
            <div class='sch'>
                <FontAwesomeIcon icon={faSearch} />
                <input type='text' placeholder='Find an employee' />
            </div>
        </div>
        <div class='rst-cont'>
            <div class='user-cont'>
                <div class='info-box'>
                    <img src={abe} />
                    <div class='user-info'>
                        <h2>Mary Brown</h2>
                        <p>Position: Secretary</p>
                        <p>Department: Media</p>
                    </div>
                </div>
                <button>Email</button>
            </div>
            <div class='user-cont'>
                <div class='info-box'>
                    <img src={Dillon} />
                    <div class='user-info'>
                        <h2>Hon. Abe Darius Dillon</h2>
                        <p>Position: Senator</p>
                        <p>Department: Boss</p>
                    </div>
                </div>
                <button>Email</button>
            </div>
        </div>
    </div>
    
  )
}
