import React, { Component, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDashboard, faThLarge } from '@fortawesome/free-solid-svg-icons'
import dashboard from "../svg/dashboard.svg"
import reports from "../svg/reports.svg"
import chart from "../svg/chart.svg"
import manager from "../svg/manager.svg"
import hr from "../svg/hr.svg"
import bird from "../../website/assests/img/welcome.png"
import bell from "../gif/bell.png"
import lady from "../../website/assests/img/dillon2.jpg"
import "../styles/topBar.css"
import getBtn from "../dev/fc"

export default class TopBar extends Component {
  render() {
    
    var menu = [
        {name: "Dashboard", href: "", svg: dashboard},
        {name: "Human Resource", href: "", svg: hr},
        {name: "Projects", href: "", svg: reports},
        {name: "Reports", href: "", svg: chart},
        {name: "Management", href: "", svg: manager},
    ] 
    return (
      <header>
    <div className='head-box'>
        <div className='inner-bar'>
             <div className='logo'>
                  <img src={bird} />
                  <input id='search' placeholder='search Office' />
             </div>
             <ul id='menu'>
                {menu.map((item, index) => {
                    return(
                        <div className='item'><img src={item.svg} /></div>
                    )
                })}
             </ul>
              <div className='user-box'>
                 <div className='svg-box'><img id='msg' src={bell} /></div>
                 <img id='user' src={lady} />
              </div>
          </div>
        </div>
      </header>
    )
  }
}
