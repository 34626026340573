import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Router, Route, Routes} from "react-router-dom"
import Home from './website/home';
import About from './website/screens/about';
import Contact from './website/screens/contact';
import Error from './website/screens/error';
import Read from './website/screens/read';
import Admin from './admin/admin';
import Dashboard from './admin/user/staff/dashboard';
import General from './admin/user/staff/general';

function App() {
  return (
    <div>

    <Routes>
        <Route path="/" element={ <Home />} />
        <Route path="/contact" element={ <Contact />} />
        <Route path="/article" element={ <Read />} />
        <Route path="/about" element = { <About /> } />
        // other routes
        <Route path='/admin' element={<Admin />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/gen' element={<General />} />
    </Routes>
    </div>

  );
}

export default App;
