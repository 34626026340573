import React from 'react'
import "../css/footer.css"

export default function Footer() {
  return (
      <footer>
            <div className="inner-footer">
                <div className="brands">
                    <div className="sub">
                        <h2>Subscribe</h2>
                        <button>Subscribe For Updates</button>
                    </div>
                    <div className="partners">
                        <div className="prt1">
                            <img src="./temp/assets/MOG.png" />
                            <p>COC</p>
                        </div>
                        <div className="prt2">
                            <img src="./temp/assets/bmg.png" />
                            <p>BMMC</p>
                        </div>
                    </div>
                    <section className="copyright">
                        <span>&copy; BMMC Copyright 2024</span>
                    </section>
                </div>
                <div className="menu">
                    <ul>
                        <li>Media Team</li>
                        <li>Administration</li>
                        <li>Terms of Use</li>
                    </ul>
                </div>
                <div className="menu">
                    <ul>
                        <li>Contact Us</li>
                        <li>Privacy Policy</li>
                        
                    </ul>
                </div>
                <div className="menu">
                    <ul>
                        <li>Suppliers</li>
                        <li>Jobs Offers</li>
                        <li>Projects</li>
                    </ul>
                </div>
                <div className="social"></div>
            </div>
        </footer>
  )
}
