import React from 'react'
import "../css/body.css"

export default function Section() {
  return (
    <div id='section'>
      <div className='sect-box'>
        <h2>What We Do</h2>
        <div className='line-box'>
            <div className='line'></div>
            <h1>O</h1>
            <div className='line'></div>
        </div>
        <p>Know our story, and how we are getting involve in the process</p>
      </div>

      <div className='story-container'>
        <div id='promise' className='story-article'>
            <div  className='txt-box'>
               <h3>Keeping The Promise</h3>
               <p>Construction of a functional rehabilitation center for vulnerable youths in and out of Montserrado</p>
               <a>Read More</a>
            </div>
        </div>
        <div id='join' className='story-article'>
        <div  className='txt-box'>
               <h3>Join Our Community</h3>
               <p>Construction of a functional rehabilitation center for vulnerable youths in and out of Montserrado</p>
               <a>Read More</a>
            </div>
        </div>
        <div id='other' className='story-article'>
        <div  className='txt-box'>
               <h3>Make Your Voice Heard</h3>
               <p>Construction of a functional rehabilitation center for vulnerable youths in and out of Montserrado</p>
               <a>Read More</a>
            </div>
        </div>
      </div>

      <div className='center-banner'>
        <div className='over'>
            <div className='txt-center'>
               <div className='sect-box'>
                   <h2>Our Achievements?</h2>
                   <div className='line-box'>
                       <div className='line'></div>
                       <h1>O</h1>
                       <div className='line'></div>
                    </div>
                    <p>Explore things we have done and continue to do</p>
                </div>
            </div>
            <div className='counter-box'>
                <div className='counter'>
                    <h1>10</h1>
                    <p>Bills Sponsored</p>
                </div>
                <div className='counter'>
                    <h1>40</h1>
                    <p>Promises kept</p>
                </div>
                <div className='counter'>
                    <h1>50k</h1>
                    <p>Grants</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
