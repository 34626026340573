import React from 'react'
import Header from './components/header'
import "./css/hd.css"
import "./css/min/iphon-android.css"
import Banner from './components/banner'
import Section from './components/section'
import Archives from './components/archives'
import News from './components/news'
import Footer from './components/footer'
import { Link } from 'react-router-dom'


export default function Home() {
  return (
    <div>
        <Header />
        <Banner />
        <Section />
        <Archives />
        <News />
        <Footer />
    </div>
  )
}
