import React from 'react'

export default function Summary({title, result, mini}) {
  return (
    <div class='summary'>
        <div class='int'><div class='intcc peach'></div><p>{title}</p></div>
        <h2>{result}</h2>
        <p>{mini}</p>
    </div>
  )
}
