import React, { Component } from 'react'
import "../css/error.css"
import Header from '../components/header'
import Footer from '../components/footer'

export default class Read extends Component {
  render() {
    return (
      <div>
        <Header />
        <main>
            <div class="section">
                <div class="texts">
                    <h2>Call For Transparency</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores quae, quos, ea, facilis tenetur veniam asperiores ipsum reprehenderit quisquam tempore culpa doloribus dolorem tempora expedita adipisci illum dolore labore quia.</p>
                </div>
                <div class="vect">
                    <img src={require("../assests/img/dillon2.jpg")} />
                </div>
            </div>
        </main>
        <Footer />
      </div>
    )
  }
}
