import React, { useEffect, useState } from 'react'
import "../../styles/dashboard.css"
import TopBar from '../../comp/topBar'
import SideBar from './comp/sideBar'
import Content from './comp/content'
import Staffs from './comp/staffs'
import Appointment from './comp/app'
import Archive from './comp/Archive'

export default function Dashboard() {
  var clicked = localStorage.getItem("idx") | 0
  const [index, setIndex] = useState(clicked)
    
  useEffect(() => {
    setTimeout(() => {
      const menuArr = document.querySelectorAll("#menu .item");
      let ind = localStorage.getItem("idx") | "";
    
    if(ind == null){
    
        menuArr[0].classList.add("clicked");
    } else {
        menuArr[ind].classList.add("clicked");
    }
    menuArr.forEach((item, i) => {
      item.addEventListener("click", (e) => {
       let current =   document.querySelector(".clicked")
       if(current != null){
          current.classList.remove("clicked")
      }
      item.classList.add("clicked")
      localStorage.setItem("idx", i);
      setIndex(i)

     // window.location.reload()
      })
    })  

  
    }, 10);

    return () => {};
  });

  return (
    <div id='dashboard-body'>
        <TopBar />
        <main id='main'>
            <div id="side-panel" class="side-panel">
              <SideBar />
            </div>
            <div class="content">
                <div id="inner-box" class="inner-container">
                  {
                    index == 0? <Content /> :
                    index == 1? <Staffs /> : 
                    index == 2? <Appointment /> : 
                    index == 3? <Archive /> : <Content />
                  }
                </div>
            </div>
            <div class="notice-board"></div>
        </main>
    </div>
  )
}
