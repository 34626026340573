import React, { useState } from 'react';
import { getFullImagePath } from "../fn/imgHelper";

const MyImage = ({ src, addBaseUrl = false, alt, className, style, ...rest }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
  
    const handleLoad = () => {
      setLoading(false);
    };
  
    const handleError = () => {
      setLoading(false);
      setError(true);
    };
  
    // Dynamically manage image path with base URL if required
    src = addBaseUrl ? getFullImagePath(src) : src;
  
    return (
      <img
        src={error ? "https://placehold.co/600x400?text=Image\n+not+Found" : loading ? "https://placehold.co/600x400?text=loading..." : src}
        alt={error ? 'Error' : alt}
        className={className}
        style={style}
        onLoad={handleLoad}
        onError={handleError}
        {...rest}
      />
    );
  };
  
  export default MyImage;