import React, { useEffect } from 'react'

export default function Appointment() {
  useEffect(() => {
    setTimeout(() =>{
      //relative & Absolute
    let relative = document.getElementById("rel");
    let relHeight = relative.getBoundingClientRect().height;
    var abs = document.getElementById("apps");
    var absHeight = abs.getBoundingClientRect().height;
    let accHeight = 10 + absHeight;
    if(relHeight < accHeight){
        relative.style.height = accHeight + "px";
    }

    const tabs = document.querySelectorAll(".tab")
    tabs.forEach((item, index) => {
        item.addEventListener("click", () => {
            document.querySelector(".active").classList.remove("active");
            item.classList.add("active");
            localStorage.setItem("tab", index);
        })
    })

    var tab = localStorage.getItem("tab") | "";
    if(tab != null){
        document.querySelector(".active").classList.remove("active");
        tabs[tab].classList.add("active");
    }
    }, 10);
  })
  const apps = [
    {day: "Fri", date: "03", time: "8:00am", loc: "Ministerial Complex", hint: "Meeting with Dr. Brown"},
    {day: "Tue", date: "08", time: "11:00am", loc: "Capital Building", hint: "Legislative Session"},
    {day: "Mon", date: "12", time: "9:00am", loc: "Executive Mansion", hint: "Meeting with the president"},
    {day: "Wed", date: "15", time: "10:00am", loc: "Farminton Hotel", hint: "Meeting with Acelor Metal"},
    {day: "Thu", date: "16", time: "11:00am", loc: "Capital Building", hint: "legislative session"},
]
  return (

      <div class='rst-cont'>
            <h1 class='heading'>Appointments</h1>
            <p class='subs'>See your scheduled events and keep track of them</p>
            <div id='tabs-box' class='tabs-box'>
                  <div class='tab active'><p>Upcoming</p></div>
                  <div class='tab'><p>Pending</p></div>
                  <div class='tab'><p>Recurring</p></div>
                  <div class='tab'><p>Past</p></div>
                  <div class='tab'><p>Cancelled</p></div>
            </div>
            <div id='rel' class='tab-cnt-box'>
                <div id='apps' class='absolute'>
                  {
                    apps.map(e => {
                      return(
                        <div class='app-box'>
                            <div class='app-info'>
                                <div class='date-day'>
                                    <p>{e.day}</p>
                                    <h2>{e.date}</h2>
                                </div>
                                <div class='time-loc'>
                                    <div><img src='../svg/clock.svg' /><p>{e.time}</p></div>
                                    <div><img src='../svg/location.svg' /><p>{e.loc}</p></div>
                                </div>
                                <div class='hint'>
                                    <p>{e.hint}</p>
                                </div>
                            </div>    
                            <button>Edit</button>
                        </div>
                      )
                    })
                  }
                </div>     
            </div>
      </div>
  )
}
