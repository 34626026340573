import React from 'react'
import "../css/contact.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRightLong} from "@fortawesome/free-solid-svg-icons"
import Header from '../components/header'
import Footer from '../components/footer'

export default function Contact() {
  return (
    <div>
        <Header />

        <main>
            <div class="section">
                <div class="form-box">
                    <form action="">
                        <h1>Contact Us</h1>
                        <input placeholder="Name" type="text" />
                        <input placeholder="Email" type="email" />
                        <textarea placeholder="Message" cols="50"></textarea>

                        <button>Send Message<FontAwesomeIcon style={{marginInline: "15px", fontSize: "18px"}} icon={faArrowRightLong} /></button>
                    </form>
                </div>
                <div class="vect">
                    <img src={require("../assests/img/contact.png")} />
                    
                </div>
            </div>
        </main>

        <Footer />
    </div>
  )
}
