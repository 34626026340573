import React, { useEffect, useState } from 'react';
import "../css/hd.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {} from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';

export default function Header() {
    const [scroll, setScroll] = useState(true)
    const [up, setUp] = useState(true)
    const [bgColor, setBgColor] = useState("#fff");
    const [h2Color, setH2Color] = useState("#05313c")
    const [txtColor, setTxtColor] = useState("#808080")
    const menulist = [
        {title: "Home", id: "Hm", href: "/"},
        {title: "Projects", id: "pr", href: "/"},
        {title: "About", id: "gv", href: "/about"},
        {title: "Contact", id: "cnt", href: "/contact"},
    ]

    var prevScrollpos = window.pageYOffset;
    let upTop = false;

    window.onscroll = function() { 
        var currentScrollPos = window.pageYOffset; 
        if (prevScrollpos > currentScrollPos) { 
          setScroll(true)
          if(currentScrollPos == 0){
            setUp(true);
            setBgColor("#fff");
            setH2Color("#05313c")
            setTxtColor("#808080")
          }
        } else {         
            setScroll(false) 
            setUp(false);
            setBgColor("#000")
            setH2Color("#fff");
            setTxtColor("#ccc")
        } 
        prevScrollpos = currentScrollPos; 
      }

  return (
    <div id='header'>
        <div className="top-bar">
            <div className='contact'>
                <p>(+231) 777-888-999 | add@gmail.com</p>
            </div>
            <div className='social'>
                <div ></div>
                <div id='yt'></div>
            </div>
        </div>
        <div style={{position: up? "static": "fixed", top: scroll? "0" : "-100%", backgroundColor: bgColor}} className="whitebar">
            <h2 style={{color: h2Color}}>Sen. Abraham Darius Dillon</h2>
            <div className='right-wing'>
                <ul style={{color: txtColor}}>
                    {menulist.map(e => {
                        return (
                            <li key={e.id}><Link style={{color: "inherit", textDecoration: "none"}} to={e.href}>{e.title}</Link></li>
                        )
                    })}
                </ul>
                <Link to={"/admin"} id='btn'>Login</Link>
            </div>
        </div>
    </div>
  )
  
}
