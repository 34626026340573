import React, { Component } from 'react'
import "../css/body.css"
import MyImage from './img'

export default class Archives extends Component {
  render() {
    const images = [
        {alt: "Photo", url: "../assests/img/dillon2.jpg"},
        {alt: "Photo", url: "../assests/img/abe.jpg"},
        {alt: "Photo", url: "../assests/img/add-clear.jpg"},
        {alt: "Photo", url: "../assests/img/add-standing.jpg"},
        {alt: "Photo", url: "../assests/img/add-swear.jpg"},
        {alt: "Photo", url: "../assests/img/cp-building\ \(3\).jpg"}
    ]
    return (
      <div id='Mission-container'>
        <div className='speech-box'>
            <div className='sect-box'>
               <h2>Our Mission</h2>
                <div className='line-box'>
                    <div className='line'></div>
                    <h1>O</h1>
                    <div className='line'></div>
                </div>
                <p style={{color: "#000"}}>Know the values that serves as the pillars to our cause</p>
           </div>

           <div className='inner-container'>
                <div id='left' className='values'>
                    <div className='vv'>
                        <h3>Transparency</h3>
                        <p>Calling for transparency in every governmental instituion</p>
                    </div>
                    <div className='vv'>
                        <h3>Rule Of Law</h3>
                        <p>Abiding solely by the organic laws of the republic without fear nor favor</p>
                    </div>
                    <div className='vv'>
                        <h3>Dehamonization</h3>
                        <p>Removing that corrupt Harmonization policy by the past regime</p>
                    </div>
                </div>
                <div className='video-cont'>
                <iframe width="400" height="300" src="https://www.youtube.com/embed/kDMdIt_dIlo?si=oBFy-GSK40GYxchB" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div id='right' className='values'>
                    <div className='vv'>
                        <h3>Salary Reduction</h3>
                        <p>Calling out public officials to reduce salaries to the bare minimum</p>
                    </div>
                    <div className='vv'>
                        <h3>Assets Declaration</h3>
                        <p>Promoting credibility and transparency</p>
                    </div>
                    <div className='vv'>
                        <h3>Development</h3>
                        <p>Empowering the youthful population of Montserrado county</p>
                    </div>
                </div>
           </div>

           <div className='media'>
               <div className='sect-box'>
                   <h2>Media Archives</h2>
                   <div className='line-box'>
                        <div className='line'></div>
                        <h1>O</h1>
                        <div className='line'></div>
                    </div>
                    <p style={{color: "#000"}}>See what you can find</p>
                </div>
           </div>
        </div>
        
        <div className='gallery'>
            <img src={require("../assests/img/abe.jpg")} />
            <img src={require("../assests/img/dillon-jnb.jpg")} />
            <img src={require("../assests/img/add-clear.jpg")} />
            <img src={require("../assests/img/add-swear.jpg")} />
            <img src={require("../assests/img/add-standing.jpg")} />
            <img src={require("../assests/img/dillon2.jpg")} />
            <img src={require("../assests/img/cp-building (3).jpg")} />
            <img src={require("../assests/img/dillon.jpg")} />
        </div>
      </div>
    )
  }
}
