import React from 'react'
import SideBar from './comp/sideBar'
import "../../styles/dashboard.css"


export default function General() {
    
  return (
    <div>
        <div id="overlay" class="overlay"></div>
         <header id="header">
         </header>

         <main>
            <div id="side-panel" class="side-panel">
                <SideBar />
            </div>
            <div class="content">
                <div id="inner-box" class="inner-container"></div>
            </div>
            <div id="board" class="notice-board"></div>
         </main>
    </div>
  )
}
